.hero {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hero.is-link {
    background-color: #485fc7;
    color: #fff;
}

.hero.is-medium .hero-body {
    padding: 12rem 4.5rem;
}

.hero-body {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 3rem 1.5rem;
}

.hero.is-link .title {
    color: #fff;
}

.hero.is-link .subtitle {
    color: rgba(255,255,255,.9);
}

.hero .title:not(.is-spaced)+.subtitle {
    margin-top: 2rem;
}

.hero-body .title {
    color: #363636;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.125;
    text-align: center;
}

.hero-body .subtitle {
    color: #4a4a4a;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
}

.subtitle, .title {
    word-break: break-word;
}


@media screen and (max-width: 769px), print {
    .hero.is-medium .hero-body {
        padding: 9rem 4.5rem;
    }
}


@media screen and (max-width: 769px), print {
    .hero-body {
        padding: 3rem 3rem;
    }
}
