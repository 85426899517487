nav a {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    /* padding: 0.5rem 1rem; */
    /* height: 100%; */
    font-weight: 400;
}

@media screen and (max-width: 960px) {
    nav {
        justify-content: left !important;
    }
    
    nav a {
        text-align: center;
        padding: 1rem;
        width: 100%;
        overflow: hidden;
    }
    nav a:hover {
        color: black;
        transition: all 0.3s ease;
    }
}

nav .buttons .button:not(:last-child):not(.is-fullwidth) {
    margin-right: .5rem;
}

nav .button.is-success.is-focused:not(:active), .button.is-success:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(72,199,142,.25);
}

nav .button.is-success.is-outlined {
    background-color: transparent;
    border-color: #48c78e;
    color: #48c78e;
}

nav .button.is-success.is-focused, .button.is-success:focus {
    border-color: transparent;
    color: #fff;
}

nav .button.is-focused:not(:active), .button:focus:not(:active) {
    box-shadow: 0 0 0 0.125em rgba(72,95,199,.25);
}

nav .buttons .button {
    margin-bottom: .5rem;
}

nav .button.is-success {
    background-color: #48c78e;
    border-color: transparent;
    color: #fff;
}

nav .button:active{
    outline: 0;
}

nav .button {
    background-color: #fff;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #111;
    cursor: pointer;
    justify-content: center;
    /* padding-left: 1em;
    padding-right: 1em; */
    padding: 0 1rem;
    text-align: center;
    white-space: nowrap;
    transition: all 0.5s;
}

nav .button{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: .375em;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(.5em - 1px);
    padding-left: calc(.75em - 1px);
    padding-right: calc(.75em - 1px);
    padding-top: calc(.5em - 1px);
    position: relative;
    vertical-align: top;
}

nav button {
    font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    margin: 0;
}

nav .button.is-success.is-outlined:focus, .button.is-success.is-outlined:hover {
    background-color: #48c78e;
    border-color: #48c78e;
    color: #fff !important;
}

nav .button.is-success.is-outlined:hover a {
    color: #fff !important;
}