#bloglist{
    flex-flow: wrap;
}

#bloglist a {
    color: #000 !important;
}

/* .card {
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
    color: #4a4a4a;
    max-width: 100%;
    position: relative;

    max-width: 24rem;
}

.card-image {
    display: block;
    position: relative;
}

.image {
    display: block;
    position: relative;
}

.image.is-4by3 {
    padding-top: 75%;
    
}

.card-image:first-child img {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.card-content:last-child, .card-footer:last-child, .card-header:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.card-content {
    background-color: transparent;
    padding: 1.5rem;
}

.media {
    align-items: flex-start;
    display: flex;
    text-align: inherit;
}

.card .media:not(:last-child) {
    margin-bottom: 1.5rem;
}

.media-left {
    margin-right: 1rem;
}

.media-left, .media-right {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
}

.image.is-48x48 {
    height: 48px;
    width: 48px;
}

.bd-snippet-preview img {
    vertical-align: top;
}

img {
    height: auto;
    max-width: 100%;
}

.image img {
    display: block;
    height: auto;
    width: 100%;
}

.media-content {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: inherit;
}

.title {
    color: #363636;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.125;
}

.subtitle {
    color: #4a4a4a;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
}


.image img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    
} */