.error404.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  background-image: url(../../Images/not_found.svg);
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  background-attachment: fixed;
  background-position: center center;
  margin-top: -80px;
}

.text-container {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}

.text-container:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.hero-container>h1 {
  color: black;
  font-size: 50px;
  margin-top: 0px;
}

.hero-container>p {
  margin-top: 8px;
  color: black;
  font-size: 1rem;
}
