/* @font-face {
    font-family: "din-2014";
    src: url("./Fonts/DIN2014-ExtraLight.woff") format("woff"), /* Modern Browsers * /
    url("./Fonts/DIN2014-ExtraLight.ttf") format("truetype");
    / * Safari, Android, iOS * /
} */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: apparat-light, sans-serif;
}

html {
    overflow-y: overlay;
}

body {
    background-color: white;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px black;
    background-color: black;
}

body::-webkit-scrollbar {
    width: 10px;
    background-color: black;
}

body::-webkit-scrollbar-thumb {
    background-color: #121212;
    border: 2px solid #121212;
}

li {
    position: relative;
    float: left;
    padding: 2px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

li:hover::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0px;
    border-bottom: 1px solid white;
}

img {
    object-fit: cover;
}

/* Button */
.hero-btns {
    position: relative;
    display: block;
    color: white;
    border: 1px solid #01bf71;
    line-height: 40px;
    padding: 0 2.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
    display: inline-block;
    margin-top: 2rem;
    border-radius: 4px;
    -webkit-transition: background .1s;
    transition: background .1s;
    font-weight: 700;
    text-decoration: none;
}

.hero-btns::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
     /* scale(1.2, 1.5); */
    height: 100%;
    width: 100%;
    /* border: 3px solid #01bf71; */
    border-radius: 3px;
}

.hero-btns:hover { 
    /* .text-container a:hover { */
    color: white;
}

.hero-btns:hover::after {
    border: 3px solid #01bf71;
    /* Alternate fixes a bug in safari */
    animation: radiate 1.5s alternate infinite;
}

.display-none {
    display: none !important;
}